import {AuthStore} from "../stores/auth.store";

export class BaseService {

    protected readonly bearerAuth: string;

    constructor(authStore: AuthStore) {
        this.bearerAuth = `Bearer ${authStore.getAccessToken()}`;
    }

    protected checkAuth(response: Response) {
        if (response.status === 401) {
            window.location.pathname = '/authorization';
        }
    }

}
