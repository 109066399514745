import DatePicker, {registerLocale} from "react-datepicker";
import ru from "date-fns/locale/ru";
import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {CloseButton, InputGroup} from "react-bootstrap";
import './datepicker.styles.scss';

import calendarIco from "../../../assets/icons/calendar.svg";

registerLocale('ru', ru);

const Datepicker = (props: { queryable: boolean }) => {

    const [query, setQuery] = useSearchParams();
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [from, to] = dateRange;

    useEffect(() => {
            if (props.queryable) {
                if (!!from && !!to) {
                    query.set('from', from.toLocaleString().slice(0, 10));
                    query.set('to', to.toLocaleString().slice(0, 10));
                    query.delete('page');
                    setQuery(query);
                } else if (!from && !to) {
                    query.delete('from');
                    query.delete('to');
                    query.delete('page');
                    setQuery(query);
                }
            }
        }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [props.queryable, from, to]);

    const clear = () => {
        setDateRange([null, null]);
    };

    return <InputGroup className="datepicker">
        <InputGroup.Text id="calendar-ico"><img src={calendarIco} alt={'ico'}/></InputGroup.Text>
        <div className="datepicker-container">
            <DatePicker
                className="form-control"
                calendarStartDay={1}
                dateFormat="dd.MM.yyyy"
                locale="ru"
                selectsRange={true}
                startDate={from}
                endDate={to}
                onChange={(update) => {
                    setDateRange(update);
                }}
                maxDate={new Date()}
                monthsShown={2}
                aria-describedby="calendar-ico"
                placeholderText="Выберите даты"
            />
        </div>
        {!!from && !!to && <CloseButton onClick={clear}/>}
    </InputGroup>;
}

export default Datepicker;
