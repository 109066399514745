import {useSearchParams} from "react-router-dom";
import {Pagination} from "react-bootstrap";
import {Pagination as PaginationType} from "../../../dto/response/response.dto";
import './pagination.styles.scss';

class Range<T> extends Array<T> {
    static create(from: number, to: number, step: number): number[] {
        return Array.from(Array(Math.floor((to - from) / step) + 1)).map(
            (v, k) => from + k * step
        );
    }
}

interface Props {
    pagination: PaginationType | undefined
}

const PaginationComponent = (props: Props) => {

    const {pagination} = props;
    const [query, setQuery] = useSearchParams();

    const onChangePage = (p: number) => {
        if (!! p) {
            query.set('page', p.toString());
            setQuery(query);
        }
    };

    return !!pagination && pagination.total_pages > 1 ? <Pagination className="pager">
        {
            pagination.total_pages < 10 ? <>
                {[...Array(pagination.total_pages)].map((_, i) => {
                    const active = i + 1 === pagination.current_page;
                    return <Pagination.Item
                        key={i}
                        onClick={() => !active && onChangePage(i + 1)}
                        active={active}>{i + 1}</Pagination.Item>;
                })}
            </> : <>
                {pagination.current_page > 3 && <Pagination.First onClick={() => onChangePage(1)}/>}
                {pagination.current_page > 3 && <Pagination.Ellipsis/>}

                {Range.create(pagination.current_page - 2, pagination.current_page + 2, 1).map((i) => {
                    let shift = 0;
                    if (pagination.current_page < 3) {
                        shift = 3 - pagination.current_page;
                    } else if (pagination.total_pages - pagination.current_page < 2) {
                        shift = (2 - (pagination.total_pages - pagination.current_page)) * -1;
                    }
                    const active = i + shift === pagination.current_page;
                    return <Pagination.Item key={i}
                                            onClick={() => !active && onChangePage(i + shift)}
                                            active={active}>{i + shift}</Pagination.Item>;
                })}

                {pagination.total_pages - pagination.current_page > 2 && <Pagination.Ellipsis/>}
                {pagination.total_pages - pagination.current_page > 2 && <Pagination.Last onClick={() => onChangePage(pagination.total_pages)}/>}
            </>
        }
    </Pagination> : <></>;
};

export default PaginationComponent;
