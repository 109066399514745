import {Route, Routes, useLocation} from "react-router-dom";
import NotFound from "../not_found/not_found.component";
import Profile from "./profile/profile.component";
import Requests from "./requests/requests.component";
import Payments from "./payments/payments.component";
import PrivateContainer from "../private_container/private_container.component";
import './client.styles.scss';

const Client = () => {

    const location = useLocation();

    return <PrivateContainer>
        <Routes location={location}>
            <Route path="profile" element={<Profile/>} />
            <Route path="requests" element={<Requests/>} />
            <Route path="payments" element={<Payments/>} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    </PrivateContainer>;
}

export default Client;
