/**
 * Simple fetch() wrapper
 * Usage:
 * new Fetcher<Type>(url).post(body).json()
 * new Fetcher<Type>(url).text() - by default method is GET
 */
class FetcherInstance<T> {

    private options: {
        [key: string]: any;
    };
    private url: string;

    constructor(url: string) {
        this.url = url;
        this.options = {};
    }

    contentType(type: string) {
        this.setHeader('Content-Type', type);
        return this;
    }

    accept(type: string) {
        this.setHeader('Accept', type);
        return this;
    }

    auth = (authString: string) => {
        this.setHeader('Authorization', authString);
        return this;
    }

    get = () => {
        this.options.method = 'GET';
        return this;
    }

    post = (body: any = null) => {
        this.setBody(body);
        this.options.method = 'POST';
        return this;
    }

    put = (body: any = null) => {
        this.setBody(body);
        this.options.method = 'PUT';
        return this;
    }

    patch = (body: any = null) => {
        this.setBody(body);
        this.options.method = 'PATCH';
        return this;
    }

    delete = () => {
        this.options.method = 'DELETE';
        return this;
    }

    json = async () => {
        this.checkMethod();
        return (await this.fetch()
            .then(response => this.checkStatus(response).json()) as T);
    }

    text = async () => {
        this.checkMethod();
        return await this.fetch().then(response => this.checkStatus(response).text());
    }

    blob = async () => {
        this.checkMethod();
        return await this.fetch().then(response => this.checkStatus(response).blob());
    }

    fetch = async () => {
        return await fetch(this.url, this.options)
            .catch(e => {
                throw(e);
            });
    }

    private checkMethod = () => {
        if (!this.options.method) {
            this.options.method = 'GET';
        }
    }

    private checkStatus = (response: Response) => {
        if (response.status >= 300) {
            throw response;
        }
        return response;
    }

    private setBody = (body: any) => {
        if (body) {
            this.options.body = this.processBody(body);
        }
    }

    private setHeader = (header: string, value: string) => {
        if (!this.options.headers) {
            this.options.headers = new Headers({
                [header]: value
            });
        } else {
            this.options.headers.set(header, value);
        }
    }

    private processBody = (body: any) => {
        if (!(body instanceof FormData)) {
            body = JSON.stringify(body);
            this.contentType('application/json');
        }
        return body;
    }
}

export default function Fetcher<T>(url: string) {
    return new FetcherInstance<T>(url);
}
