import {Button, CloseButton, InputGroup, OverlayTrigger, Popover, Row, Col} from "react-bootstrap";
import {FormEvent, useContext, useState} from "react";
import DatePicker, {registerLocale} from "react-datepicker";
import ru from "date-fns/locale/ru";
import {ClientService} from "../../../../services/client.service";
import {StoreContext} from "../../../../store.context";
import '../../datepicker/datepicker.styles.scss';
import './download.styles.scss';

import calendarIco from "../../../../assets/icons/calendar.svg";

registerLocale('ru', ru);

const Download = () => {

    const {authStore} = useContext(StoreContext);
    const [dateRange, setDateRange] = useState<[Date | null, Date | null]>([null, null]);
    const [from, to] = dateRange;

    const downloadBlob = (blob: Blob) => {
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");

        link.href = blobUrl;
        link.download = 'APCG agent report ' +
            from?.toLocaleString().slice(0, 10).replaceAll('.', '-') +
            ' - ' +
            to?.toLocaleString().slice(0, 10).replaceAll('.', '-') +
            '.xlsx';

        document.body.appendChild(link);

        link.dispatchEvent(
            new MouseEvent('click', {
                bubbles: true,
                cancelable: true,
                view: window
            })
        );
        document.body.removeChild(link);
    };

    const onClick = (e: FormEvent) => {
        e.preventDefault();
        e.stopPropagation();

        if (!!from && !!to) {
            new ClientService(authStore)
                .report(
                    from.toLocaleString().slice(0, 10),
                    to.toLocaleString().slice(0, 10)
                )
                .then((blob) => {
                    downloadBlob(blob);
                });
        }
    };

    const clear = () => {
        setDateRange([null, null]);
    };

    const downloadPopover = (
        <Popover id="download-popover">
            <Popover.Header as="h4">Выберите даты</Popover.Header>
            <Popover.Body>
                <Row className="mb-3">
                    <Col>
                        <InputGroup className="datepicker">
                            <InputGroup.Text id="calendar-ico"><img src={calendarIco} alt={'ico'}/></InputGroup.Text>
                            <div className="datepicker-container">
                                <DatePicker
                                    className="form-control"
                                    calendarStartDay={1}
                                    dateFormat="dd.MM.yyyy"
                                    locale="ru"
                                    selectsRange={true}
                                    startDate={from}
                                    endDate={to}
                                    onChange={(update) => {
                                        setDateRange(update);
                                    }}
                                    maxDate={new Date()}
                                />
                            </div>
                            {!!from && !!to && <CloseButton onClick={clear}/>}
                        </InputGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Button className="w-100" variant="dark" disabled={!from || !to} onClick={onClick}>Скачать</Button>
                    </Col>
                </Row>
            </Popover.Body>
        </Popover>
    );

    return <OverlayTrigger trigger="click" placement="bottom-start" rootClose={true} overlay={downloadPopover}>
        <Button variant="dark">Получить отчет Агента</Button>
    </OverlayTrigger>;
};

export default Download;
