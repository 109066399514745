import React, {FormEvent, useContext, useEffect, useState} from "react";
import {NavLink, useNavigate} from "react-router-dom";
import {observer} from "mobx-react-lite";
import {Alert, Button, Card, Col, Container, Form, Row} from "react-bootstrap";
import {StoreContext} from "../../store.context";
import './authorization.styles.scss';

import logo from "../../assets/images/logo-black.png";

const AuthorizationView = () => {

    const navigate = useNavigate();
    const {authStore} = useContext(StoreContext);

    const [isShowError, setIsShowError] = useState<boolean>(false);
    const [crmId, setCrmId] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    useEffect(
        () => {
            authStore.logout();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const onChangeCrmId = (e: FormEvent) => {
        setCrmId((e.target as HTMLInputElement).value);
    };

    const onChangePassword = (e: FormEvent) => {
        setPassword((e.target as HTMLInputElement).value);
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();
        setIsLoading(true);
        await authStore.login({crm_id: crmId, password, grant_type: 'password'});
        setIsLoading(false);
        if (authStore.isAuthenticated()) {
            setIsShowError(false);
            navigate('/client/profile');
        } else {
            setIsShowError(true);
        }
    }

    return (
        <Container className="position-relative">
            <Row className="position-absolute w-100">
                <Col>
                    <div className="client-header">
                        <NavLink to={'/'} className="client-header-logo">
                            <img src={logo} alt={'logo'}/>
                        </NavLink>
                        <a href="tel:84959374860" className="client-header-phone">+7 495 937 48 60</a>
                    </div>
                </Col>
            </Row>
            <Row className="vh-100 justify-content-center align-items-center">
                <Col md="auto">
                    <Card className="auth-card">
                        <Card.Body>
                            <h1 className="text-center">Личный кабинет</h1>
                            <Alert variant="danger" hidden={!isShowError}>
                                 Пользователь с таким ID и паролем не найден
                            </Alert>
                            <Form onSubmit={onSubmit}>
                                <Form.Group className="mb-3" controlId="formBasicEmail">
                                    <Form.Control
                                        required
                                        value={crmId}
                                        type="text"
                                        placeholder="Ваш ID"
                                        onChange={onChangeCrmId}
                                    />
                                    <Form.Text className="text-muted text-center d-block w-100">
                                        Получите ваш ID у менеджера
                                    </Form.Text>
                                </Form.Group>

                                <Form.Group className="mb-3" controlId="formBasicPassword">
                                    <Form.Control
                                        required
                                        value={password}
                                        type="password"
                                        placeholder="Пароль"
                                        onChange={onChangePassword}
                                    />
                                </Form.Group>
                                <Button
                                    variant="dark"
                                    type="submit"
                                    disabled={!crmId || !password || isLoading}
                                >
                                    Войти
                                </Button>
                            </Form>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
};

const Authorization = observer(AuthorizationView);
export default Authorization;
