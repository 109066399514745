import {Route, Routes, useLocation} from 'react-router-dom';
import App from '../app/app.component';
import Authorization from '../authorization/authorization.component';
import Client from '../client/client.component';
import NotFound from '../not_found/not_found.component';

const Router = () => {

    const location = useLocation();

    return (
        <Routes location={location}>
            <Route path="/authorization" element={<Authorization/>} />
            <Route path="/client/*" element={<Client/>} />
            <Route path="/" element={<App/>} />
            <Route path="*" element={<NotFound />} />
        </Routes>
    );
}

export default Router;
