import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {StoreContext} from "../../store.context";

const PrivateContainer: React.FC = ({children}) => {

    const navigate = useNavigate();
    const {authStore} = useContext(StoreContext);
    const [isReady, setIsReady] = useState<boolean>(false);

    useEffect(
        () => {
            if (!authStore.isAuthenticated()) {
                navigate('/authorization')
            } else {
                setIsReady(true);
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return <>
        {isReady && children}
    </>;
}

export default PrivateContainer;
