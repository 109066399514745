const NotFound = () => <main
    style={{
        height: "100vh",
        width: "100vw",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        overflow: "hidden"
    }}>
    <h1 style={{fontSize: "10rem", marginBottom: "1rem"}}>404</h1>
</main>

export default NotFound;
