import React from "react";
import {Container, Row, Col, Button} from "react-bootstrap";
import {NavLink} from "react-router-dom";
import Sidebar from "./sidebar/sidebar.component";

import logo from '../../../assets/images/logo-black.png';

const ClientContainer: React.FC = ({children}) => {
  return <Container fluid={'lg'}>
      <Row>
          <Col>
              <div className="client-header">
                  <NavLink to={'/'} className="client-header-logo">
                      <img src={logo} alt={'logo'}/>
                  </NavLink>
                  <div>
                      <a href="tel:84959374860" className="client-header-phone">+7 495 937 48 60</a>
                      <NavLink to={'/authorization'} className="client-header-logout">Выйти</NavLink>
                  </div>
              </div>
          </Col>
      </Row>
      <Row>
          <Col xs='auto'>
              <Sidebar/>
          </Col>
          <Col>{children}</Col>
      </Row>
  </Container>;
};

export default ClientContainer;
