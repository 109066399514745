import {useSearchParams} from "react-router-dom";
import {useContext, useEffect, useState} from "react";
import {Col, Row, Spinner, Table} from "react-bootstrap";
import {format} from "date-fns";
import ClientContainer from "../container/container.component";
import {StoreContext} from "../../../store.context";
import {Pagination} from "../../../dto/response/response.dto";
import {Payment} from "../../../dto/response/payments.dto";
import {ClientService} from "../../../services/client.service";
import Datepicker from "../datepicker/datepicker.component";
import SearchInput from "../search/search.component";
import PaginationComponent from "../pagination/pagination.component";
import Download from "./download/download.component";
import './payments.styles.scss';

const Payments = () => {

    const [query] = useSearchParams();
    const {authStore} = useContext(StoreContext);
    const [payments, setPayments] = useState<Payment[] | undefined>(undefined);
    const [pagination, setPagination] = useState<Pagination | undefined>(undefined);

    const fetch = () => {
        new ClientService(authStore)
            .payments(
                parseInt(query.get('page') || '1'),
                parseInt(query.get('per') || '10'),
                query.get('q'),
                query.get('from'),
                query.get('to')
            )
            .then((paymentsResponse) => {
                setPayments(paymentsResponse.result);
                setPagination(paymentsResponse.pagination);
            });
    };

    useEffect(
        () => {
            fetch();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [query.toString()]
    );

    return <ClientContainer>
        <Row className="mb-4">
            <Col xs={3}>
                <SearchInput/>
            </Col>
            <Col xs={3}>
                <Datepicker queryable={true}/>
            </Col>
            <Col className="d-flex justify-content-end">
                <Download/>
            </Col>
        </Row>
        <Row>
            <Col>
                {!!payments ? <Table responsive className="payments-table">
                    <thead>
                    <tr>
                        <th>Номер заказа</th>
                        <th>Дата</th>
                        <th>Содержание заявки</th>
                        <th>Поставщик</th>
                        <th>Сумма,
                            перечисленная
                            Обществу, руб.</th>
                        <th>Сумма,
                            перечисленная
                            Поставщику
                            (Исполнителю) руб.</th>
                        <th>Размер
                            агентского
                            вознаграждения
                            руб.</th>
                    </tr>
                    </thead>
                    <tbody>
                    {payments.map((payment) => {
                        return <tr key={payment.request_id}>
                            <td>{payment.request_id}</td>
                            <td>{format(new Date(payment.completed_on), 'dd.MM.yyyy')}</td>
                            <td>{payment.description}</td>
                            <td>{payment.supplier_name}</td>
                            <td>{parseFloat(payment.gross_amount).toFixed(2)} ₽</td>
                            <td>{parseFloat(payment.net_amount).toFixed(2)} ₽</td>
                            <td>{parseFloat(payment.delta_amount).toFixed(2)} ₽</td>
                        </tr>
                    })}
                    </tbody>
                </Table> : <Spinner animation="grow" />}
                <PaginationComponent pagination={pagination}/>
            </Col>
        </Row>
    </ClientContainer>;
};

export default Payments;
