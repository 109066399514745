import {makeAutoObservable} from "mobx";
import {LoginRequest} from "../dto/request/login.dto";
import {AuthService} from "../services/auth.service";
import {LogoutRequest} from "../dto/request/logout.dto";

export class AuthStore {

    private authenticated = false;

    constructor(private readonly authService: AuthService) {
        makeAutoObservable(this);
        this.authenticated = !!this.getAccessToken();
    }

    async login(loginRequest: LoginRequest) {
        try {
            const tokenPayloadDto = await this.authService.login(loginRequest);
            localStorage.setItem("access_token", tokenPayloadDto.access_token);
            localStorage.setItem("refresh_token", tokenPayloadDto.refresh_token);
            this.setAuthenticated(true);
        } catch (err) {
            this.setAuthenticated(false);
        }
    }

    async logout() {
        const token = this.getAccessToken();
        if (!! token) {
            const logoutRequest: LogoutRequest = { token };
            await this.authService.logout(logoutRequest);
        }
        localStorage.removeItem("access_token");
        localStorage.removeItem("refresh_token");
        this.setAuthenticated(false);
    }

    private setAuthenticated(authenticated: boolean) {
        this.authenticated = authenticated;
    }

    getAccessToken() {
        return localStorage.getItem("access_token");
    }

    getRefreshToken() {
        return localStorage.getItem("refresh_token");
    }

    isAuthenticated() {
        return this.authenticated;
    }

}
