import {useContext, useEffect, useState} from "react";
import {Card, Spinner} from "react-bootstrap";
import {format} from "date-fns";
import ClientContainer from "../container/container.component";
import {ClientProfile} from "../../../dto/response/profile.dto";
import {ClientService} from "../../../services/client.service";
import {StoreContext} from "../../../store.context";
import './profile.styles.scss';

const Profile = () => {

    const {authStore} = useContext(StoreContext);
    const [profile, setProfile] = useState<ClientProfile | undefined>(undefined);

    useEffect(
        () => {
            new ClientService(authStore)
                .profile()
                .then((profileResponse) => {
                    setProfile(profileResponse.result);
                });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    return <ClientContainer>
        {!!profile ? <>
            <Card className="profile-card">
                <Card.Body>
                    <Card.Title>{profile.cyrillic_first_name} {profile.cyrillic_last_name}</Card.Title>
                    <p><span className="text-muted text-uppercase">Телефон:</span> {profile.phone_code}{profile.phone}</p>
                    {!!profile.birth_date && <p><span className="text-muted text-uppercase">Дата рождения:</span> {format(new Date(profile.birth_date), 'dd.MM.yyyy')}</p>}
                    {!!profile.city && !!profile.country &&
                    <p><span className="text-muted text-uppercase">Город:</span> {profile.city}, {profile.country}</p>}
                    <p className="mb-0"><span className="text-muted text-uppercase">Членство истекает:</span> {format(new Date(profile.membership_end), 'dd.MM.yyyy')}</p>
                </Card.Body>
            </Card>
            {profile.emails.length > 0 && <Card className="profile-card">
              <Card.Body>
                <Card.Title>Emails:</Card.Title>
                  {profile.emails.map((email) => {
                      return <p key={email.id}>{email.email}</p>
                  })}
              </Card.Body>
            </Card>}
            {profile.addresses.length > 0 && <Card className="profile-card">
              <Card.Body>
                <Card.Title>Адреса:</Card.Title>
                  {profile.addresses.map((address) => {
                      return <p key={address.id}>{address.address}</p>
                  })}
              </Card.Body>
            </Card>}
        </> : <Spinner animation="grow"/>}
    </ClientContainer>;
};

export default Profile;
