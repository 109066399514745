import Fetcher from "../utils/fetcher";
import queryString from "../utils/query";
import {ProfileResponse} from "../dto/response/profile.dto";
import {BaseService} from "./base.service";
import {RequestsResponse} from "../dto/response/requests.dto";
import {PaymentsResponse} from "../dto/response/payments.dto";

export class ClientService extends BaseService {

    async profile() {
        try {
            return await Fetcher<ProfileResponse>('/backend/api/client/v1/customer')
                .auth(this.bearerAuth)
                .json();
        } catch (e: any) {
            this.checkAuth(e);
            throw new Error(e);
        }
    }

    async requests(
        page = 1, per = 10,
        q: string | null = null, from: string | null = null,
        to: string | null = null
    ) {
        try {
            const query = queryString({ page, per, q, from, to });
            return await Fetcher<RequestsResponse>(`/backend/api/client/v1/customer/requests?${query}`)
                .auth(this.bearerAuth)
                .json();
        } catch (e: any) {
            this.checkAuth(e);
            throw new Error(e);
        }
    }

    async payments(
        page = 1, per = 10,
        q: string | null = null, from: string | null = null,
        to: string | null = null
    ) {
        try {
            const query = queryString({ page, per, q, from, to });
            return await Fetcher<PaymentsResponse>(`/backend/api/client/v1/customer/payments?${query}`)
                .auth(this.bearerAuth)
                .json();
        } catch (e: any) {
            this.checkAuth(e);
            throw new Error(e);
        }
    }

    async report(from: string | null = null, to: string | null = null) {
        try {
            const query = queryString({ from, to });
            return await Fetcher(`/backend/api/client/v1/customer/report?${query}`)
                .auth(this.bearerAuth)
                .blob();
        } catch (e: any) {
            this.checkAuth(e);
            throw new Error(e);
        }
    }

}
