import React, {FormEvent, useState} from "react";
import {Alert, Button, CloseButton, Container, Form, Modal, ModalBody} from "react-bootstrap";
import {validateEmail} from "../../utils/helpers";
import {RegisterService} from "../../services/register.service";

interface Props {
    onHide: () => any;
}

const RegistrationView = (props: Props) => {

    const [isShowError, setIsShowError] = useState<boolean>(false);
    const [isRegistrationSuccess, setIsRegistrationSuccess] = useState<boolean>(false);

    const [fio, setFio] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [isPhoneValid, setIsPhoneValid] = useState<boolean>(true);
    const [email, setEmail] = useState<string>("");
    const [isEmailValid, setIsEmailValid] = useState<boolean>(true);
    const [address, setAddress] = useState<string>("");
    const [isOfertaAccept, setIsOfertaAccept] = useState<boolean>(false);
    const [isPersonalAccept, setIsPersonalAccept] = useState<boolean>(false);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const onChangeFio = (e: FormEvent) => {
        setFio((e.target as HTMLInputElement).value);
    };

    const onChangePhone = (e: FormEvent) => {
        setPhone((e.target as HTMLInputElement).value);
        setIsPhoneValid(true);
    }

    const onChangeEmail = (e: FormEvent) => {
        setEmail((e.target as HTMLInputElement).value);
        setIsEmailValid(true);
    }

    const onChangeAddress = (e: FormEvent) => {
        setAddress((e.target as HTMLInputElement).value);
    }

    const onSubmit = async (e: FormEvent) => {
        e.preventDefault();

        const isValidEmail = !email || validateEmail(email);
        setIsEmailValid(isValidEmail);
        const isValidPhone = !/[a-zа-яё]/i.test(phone);
        setIsPhoneValid(isValidPhone);

        if (isValidEmail && isValidPhone) {
            setIsLoading(true);
            setIsShowError(false);
            try {
                const response = await RegisterService.register({
                    name: fio,
                    phone,
                    email,
                    address
                });
                setIsLoading(false);
                if (response.status === 200) {
                    setIsRegistrationSuccess(true);
                }
            } catch (e) {
                setIsLoading(false);
                setIsRegistrationSuccess(false);
                setIsShowError(true);
            }
        }
    }

    return (
        <Container>
            <Modal show={true}>
                {!isRegistrationSuccess && <ModalBody style={{paddingLeft: '3vw', paddingRight: '3vw', paddingTop: '6vh', paddingBottom: '6vh'}}>
                    <CloseButton style={{position: 'absolute', top: 16, right: 16}} onClick={props.onHide}/>
                    <div className="content-block__header"
                         style={{textAlign: 'start', marginBottom: 30, fontSize: '48px', lineHeight: '48px'}}>Станьте
                        нашим клиентом
                    </div>

                    <Form onSubmit={onSubmit} className="reg-form">
                        <Form.Group className="mb-3">
                            <Form.Control
                                required
                                type="text"
                                placeholder="ФИО"
                                onChange={onChangeFio}
                            />
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control
                                required
                                placeholder="Номер телефона"
                                onChange={onChangePhone}
                            />
                            {!isPhoneValid && <Form.Text style={{color: '#DB4A4A', fontSize: 12, marginLeft: 12}}>
                                Неверный номер
                            </Form.Text>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control
                                type="text"
                                placeholder="Электронная почта"
                                onChange={onChangeEmail}
                            />
                            {!isEmailValid && <Form.Text style={{color: '#DB4A4A', fontSize: 12, marginLeft: 12}}>
                                Неверный email
                            </Form.Text>}
                        </Form.Group>

                        <Form.Group className="mb-3">
                            <Form.Control placeholder="Адрес" onChange={onChangeAddress}/>
                        </Form.Group>

                        <Form.Check
                            checked={isOfertaAccept}
                            onChange={(e) => {
                                setIsOfertaAccept(!isOfertaAccept);
                            }}
                            label={<div>Вы соглашаетесь с <a href="/files/oferta.pdf" target="_blank" rel="noreferrer" style={{color: '#212529'}}>условиями
                                оферты</a></div>}/>
                        <Form.Check
                            checked={isPersonalAccept}
                            onChange={(e) => {
                                setIsPersonalAccept(!isPersonalAccept);
                            }}
                            style={{marginTop: 8}}
                            label='Вы даете согласие на обработку персональных данных'/>

                        <Button
                            variant="dark"
                            type="submit"
                            disabled={!fio || !phone || !email || !isOfertaAccept || !isPersonalAccept || isLoading}
                            style={{borderRadius: 35, fontFamily: 'raleway-regular', fontSize: 18, marginTop: 40}}
                        >
                            Отправить заявку
                        </Button>

                        <Alert variant="danger" style={{marginTop: 16}} hidden={!isShowError}>
                            Упс... Произошла непредвиденная ошибка
                        </Alert>
                    </Form>
                </ModalBody>}
                {isRegistrationSuccess && <ModalBody style={{paddingLeft: '3vw', paddingRight: '5vw', paddingTop: '6vh', paddingBottom: '10vh'}}>
                  <CloseButton style={{position: 'absolute', top: 16, right: 16}} onClick={props.onHide}/>
                    <div className="content-block__header"
                         style={{textAlign: 'start', marginBottom: 30, fontSize: '48px', lineHeight: '48px'}}>Спасибо за
                        запрос!
                    </div>
                    <p>Мы свяжемся с вами в ближайшее время</p>
                </ModalBody>}
            </Modal>
        </Container>
    );
};

export default RegistrationView;
