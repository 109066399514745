import {useContext, useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {format} from "date-fns";
import {Col, Row, Spinner, Table} from "react-bootstrap";
import ClientContainer from "../container/container.component";
import {StoreContext} from "../../../store.context";
import {ClientService} from "../../../services/client.service";
import {ClientRequest} from "../../../dto/response/requests.dto";
import {Pagination} from "../../../dto/response/response.dto";
import PaginationComponent from "../pagination/pagination.component";
import Datepicker from "../datepicker/datepicker.component";
import SearchInput from "../search/search.component";
import Status from "./status/status.component";

const Requests = () => {

    const [query] = useSearchParams();
    const {authStore} = useContext(StoreContext);
    const [requests, setRequests] = useState<ClientRequest[] | undefined>(undefined);
    const [pagination, setPagination] = useState<Pagination | undefined>(undefined);


    const fetch = () => {
        new ClientService(authStore)
            .requests(
                parseInt(query.get('page') || '1'),
                parseInt(query.get('per') || '10'),
                query.get('q'),
                query.get('from'),
                query.get('to')
            )
            .then((requestsResponse) => {
                setRequests(requestsResponse.result);
                setPagination(requestsResponse.pagination);
            });
    };

    useEffect(
        () => {
            fetch();
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [query.toString()]
    );

    return <ClientContainer>
        <Row className="mb-4">
            <Col xs={3}>
                <SearchInput/>
            </Col>
            <Col xs={3}>
                <Datepicker queryable={true}/>
            </Col>
        </Row>
        <Row>
            <Col>
                {!!requests ? <Table responsive>
                    <thead>
                    <tr>
                        <th>Дата</th>
                        <th>Описание</th>
                        <th>Статус</th>
                    </tr>
                    </thead>
                    <tbody>
                    {requests.map((request) => {
                        return <tr key={request.id}>
                            <td className="text-center">{format(new Date(request.start_date), 'dd.MM.yyyy')}</td>
                            <td>{request.description}</td>
                            <td className="text-center"><Status statusId={request.status_id}/></td>
                        </tr>
                    })}
                    </tbody>
                </Table> : <Spinner animation="grow"/>}
                <PaginationComponent pagination={pagination}/>
            </Col>
        </Row>
    </ClientContainer>;
};

export default Requests;
