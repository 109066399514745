import { Nav } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import './sidebar.styles.scss';

import profileIco from '../../../../assets/icons/profile.svg';
import requestsIco from '../../../../assets/icons/requests.svg';
import paymentsIco from '../../../../assets/icons/payments.svg';

const Sidebar = () => {
    return <Nav defaultActiveKey="/client/profile" className="sidebar flex-column">
        <Nav.Link as={NavLink} to="/client/profile">
            <span><img className="nav-link-ico" src={profileIco} alt={'ico'}/>Личная информация</span>
        </Nav.Link>
        <Nav.Link as={NavLink} to="/client/requests">
            <span><img className="nav-link-ico" src={requestsIco} alt={'ico'}/>Запросы</span>
        </Nav.Link>
        <Nav.Link as={NavLink} to="/client/payments">
            <span><img className="nav-link-ico" src={paymentsIco} alt={'ico'}/>Платежи</span>
        </Nav.Link>
    </Nav>;
}

export default Sidebar;
