import Fetcher from "../utils/fetcher";
import {RegisterRequest} from "../dto/request/register.dto";

export class RegisterService {

    static async register(registerRequest: RegisterRequest) {
        try {
            return await Fetcher('/backend/api/client/v1/register')
                .post(registerRequest)
                .fetch();
        } catch (e: any) {
            throw new Error(e);
        }
    }

}
