import Fetcher from "../utils/fetcher";
import {LoginRequest} from "../dto/request/login.dto";
import {LoginResponse} from "../dto/response/login.dto";
import {LogoutRequest} from "../dto/request/logout.dto";

export class AuthService {

    async login(loginRequest: LoginRequest) {
        try {
            return await Fetcher<LoginResponse>('/backend/oauth/token')
                .post(loginRequest)
                .json();
        } catch (e: any) {
            throw new Error(e);
        }
    }

    async logout(logoutRequest: LogoutRequest) {
        try {
            return await Fetcher('/backend/oauth/revoke')
                .post(logoutRequest)
                .json();
        } catch (e: any) {
            throw new Error(e);
        }
    }

}
