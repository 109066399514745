import {useEffect, useState} from "react";
import {useSearchParams} from "react-router-dom";
import {CloseButton, FormControl, InputGroup} from "react-bootstrap";
import useSearchDebounce from "../../../hooks/useSearchDebounce";
import './search.styles.scss';

import searchIco from '../../../assets/icons/search.svg';

const SearchInput = () => {

    const [query, setQuery] = useSearchParams();
    const [search, setSearch] = useState<string>('');
    const debouncedSearch = useSearchDebounce(search, 1000);

    useEffect(() => {
        if (!!debouncedSearch) {
            query.set('q', debouncedSearch);
            query.delete('page');
        } else {
            query.delete('q');
            query.delete('page');
        }
        setQuery(query);
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
        [debouncedSearch]);

    const clear = () => {
        setSearch('');
        query.delete('q');
        query.delete('page');
        setQuery(query);
    };

    return <InputGroup className="search-field">
        <InputGroup.Text id="search-ico"><img src={searchIco} alt={'ico'}/></InputGroup.Text>
        <FormControl
            value={search}
            onChange={(e) => setSearch(e.target.value)}
            placeholder="Поиск"
            aria-label="Поиск"
            aria-describedby="search-ico"
        />
        {!! debouncedSearch && <CloseButton onClick={clear}/>}
    </InputGroup>;
}

export default SearchInput;
