import { Badge } from "react-bootstrap";
import './status.styles.scss';

interface Props {
    statusId: number;
}

const Status = (props: Props) => {
    if (props.statusId === 4) {
        return <div className="status"><Badge className="status__success">Завершено</Badge></div>;
    } else if (props.statusId === 2) {
        return <div className="status"><Badge className="status__in_progress">Выполняется</Badge></div>;
    } else {
        return <div className="status"><Badge className="status__cancel">Отменен</Badge></div>;
    }
};

export default Status;
